import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Elements from './elements.js';
import Title from './title.js';
import useApiRequest from '../../hooks/useApiRequest/';
import { FETCHING, SUCCESS, ERROR } from '../../hooks/useApiRequest/actionTypes';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import {Grid} from '@material-ui/core';
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./arrows";
import { Card } from './card';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240,
  },
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const Landing = (props) => {
  
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  
  const [tags, setTags] = useState([]);

  const [o1, makeRequest1] = useApiRequest(`${props.base_url}/review/json/list/1?bot=0`);
  const [o5, makeRequest5] = useApiRequest(`${props.base_url}/review/json/list/1?bot=1`);
  const [o6, makeRequest6] = useApiRequest(`${props.base_url}/node/json/viewed/1?type=6`);
  const [o7, makeRequest7] = useApiRequest(`${props.base_url}/node/json/viewed/1?type=1`);
  const [o8, makeRequest8] = useApiRequest(`${props.base_url}/node/json/viewed/1?type=2`);

  const [o, makeRequest] = useApiRequest(`${props.base_url}/tag/json/top`);

  const arr_o = []
  for(let i=0; i<5; i++){
    arr_o.push(useApiRequest(``));
  }

  function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
  
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  React.useEffect(() => {

    makeRequest().then(function(r){
      console.log(">>", r.data.length);
      const arr_tags = [];
      for(var i=0; i<r.data.length; i++){
        arr_o[i][1](`${props.base_url}/node/json/viewed/1?tag=${r.data[i]}`);
        //arr_tags.push(r.data[i]);
        arr_tags.push(r.data[i]);
      }
      setTags(arr_tags);
    });
    
    makeRequest1();
    makeRequest5();
    makeRequest6();
    makeRequest7();
    makeRequest8();

    //menuItems = Menu(list, selected);
    },[]);
  
  return (<> 
    {(o1.status === ERROR || o5.status === ERROR || o6.status === ERROR || o7.status === ERROR || o8.status === ERROR) && (
      <div className="api-request__error-container">
        <br />
        <div className="api-request__error-response">
          SERVER ERROR 
        </div>
      </div>
    )}
    <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper} style={{height:'450px'}}>
                <Elements title='Ofertas destacadas' rows={o6.status === SUCCESS ? o6.response.data.items : []} showMore={true} more='/shopping?type=6'/>
              </Paper>
              <div style={{width:'100%', display:o6.status===FETCHING?'block':'none'}}>
                <LinearProgress className="" variant="indeterminate" color="primary" />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper} style={{height:'450px'}}>
                <Elements title='Elementos m&aacute;s cotizados' rows={o7.status === SUCCESS ? o7.response.data.items : []} showMore={false}/>
              </Paper>
              <div style={{width:'100%', display:o7.status===FETCHING?'block':'none'}}>
                <LinearProgress className="" variant="indeterminate" color="primary" />
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper className={classes.paper} style={{height:'450px'}}>
                <Elements title='Revisiones virtuales recientes' rows={o5.status === SUCCESS ? o5.response.data.items : []} showMore={false}/>
              </Paper>
              <div style={{width:'100%', display:o5.status===FETCHING?'block':'none'}}>
                <LinearProgress className="" variant="indeterminate" color="primary" />
              </div>
            </Grid>
            
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{minHeight:'350px', boxShadow:"none"}}>
              <Title>Revisiones en terreno recientes</Title>
             
                {o1.status === SUCCESS && (
                  <ScrollMenu
                  LeftArrow={LeftArrow}
                  onWheel={onWheel}
                  RightArrow={ RightArrow }
                  >
                    {o1.response.data.items.map(el => {
                      const {id_n,nn,ri} = el;
                      return <Card
                        title={nn}
                        itemId={id_n}
                        key={id_n}
                        img={ri}
                        id={id_n}
                      />
                    })
                    }
                  </ScrollMenu>
                )}
              </Paper>
              <div style={{width:'100%', display:o1.status===FETCHING?'block':'none'}}>
                <LinearProgress className="" variant="indeterminate" color="primary" />
              </div>
            </Grid>

            {tags.map((v,k) => 
              <Grid key={k} item xs={12}>
                <Paper className={classes.paper} style={{minHeight:'350px', boxShadow:"none"}}>
                <Title> #{ v } </Title>
              
                  {arr_o[k][0].status === SUCCESS && (
                    <ScrollMenu
                    LeftArrow={LeftArrow}
                    onWheel={onWheel}
                    RightArrow={ RightArrow }
                    >
                      {arr_o[k][0].response.data.items.map(el => {
                        const {id_n,nn,ni} = el;
                        return <Card
                          title={nn}
                          itemId={id_n}
                          key={id_n}
                          img={ni}
                          id={id_n}
                        />
                      })
                      }
                    </ScrollMenu>
                  )}
                </Paper>
                <div style={{width:'100%', display:arr_o[k][0].status===FETCHING?'block':'none'}}>
                  <LinearProgress className="" variant="indeterminate" color="primary" />
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              <Paper className={classes.paper} style={{minHeight:'350px', boxShadow:"none"}}>
              <Title>Lugares Destacados</Title>
             
                {o8.status === SUCCESS && (
                  <ScrollMenu
                  LeftArrow={LeftArrow}
                  onWheel={onWheel}
                  RightArrow={ RightArrow }
                  >
                    {o8.response.data.items.map(el => {
                      const {id_n,nn,ni} = el;
                      return <Card
                        title={nn}
                        itemId={id_n}
                        key={id_n}
                        img={ni}
                        id={id_n}
                      />
                    })
                    }
                  </ScrollMenu>
                )}
              </Paper>
              <div style={{width:'100%', display:o8.status===FETCHING?'block':'none'}}>
                <LinearProgress className="" variant="indeterminate" color="primary" />
              </div>
            </Grid>

          </Grid>
        </Container>
      </main>
  </>);
}

export default Landing;