import React from 'react';
//import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './title.js';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

const toC = number => {
  const formatter = new Intl.NumberFormat("de-DE", {
   
  });
  return formatter.format(number.replace('.0000',''));
}

export default function Elements(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Title>{ props.title }</Title>
      <Table size="small">
        <TableHead>
          <TableRow >
            <TableCell>Elemento</TableCell>
            <TableCell align="right">Precio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow key={row.id_n}>
              <TableCell><Link to={`/${row.id_n}`} style={{textDecoration: 'none'}}>{row.nn}</Link></TableCell>
              {row.delta && (<TableCell align="right"><span style={{display:'block',whiteSpace:'nowrap'}}>CLP {toC(row.np)}</span><span style={{display:'block',whiteSpace:'nowrap'}}><del>CLP {toC(row.pbm)}</del></span><span style={{display:'block',whiteSpace:'nowrap'}}>Delta {toC(row.delta)}</span></TableCell>)}
              {!row.delta && (<TableCell align="right"><span style={{display:'block',whiteSpace:'nowrap'}}>CLP {row.np ? toC(row.np) : toC(row.rp)}</span></TableCell>)}
            </TableRow>
            
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore} style={{display:props.showMore?'block':'none'}}>
        <Link color="primary" to={`${props.more}`}>
          Ver m&aacute;s
        </Link>
      </div>
    </React.Fragment>
  );
}

