import React from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function Card({title, itemId, img, id}) {
  const visibility = React.useContext(VisibilityContext);
  const visible = visibility.isItemVisible(itemId);
  
  const e = (e) => {
		e.target.src = 'https://deltaquo.com/static/278_2.jpg';
	};
  
  return (
    <div
      role="button"
      style={{
        display: "inline-block",
        margin: "0 2px",
        width: "150px",
        userSelect: "none",
        border: '1px solid #CCC',
        borderRadius: '10px',
        padding: '10px'
      }}
      tabIndex={0}
      className="card"
    >
    <a href={`/${id}`} style={{textDecoration:"none"}}>
      <div>
        <div style={{ textAlign:'center' }}>
          <img src={img} onError={e} style={{width:'150px', height:'150px', filter: visible ? 'grayscale(0)' : 'grayscale(75%)', transition: '.3s ease-in-out'}} />
          <p style={{minHeight:'100px'}}>{title}</p>
        </div>
      </div>
    </a>
    </div>
  );
}
