import React from "react";

function ImageTL(props) {
  return (
    <>
      <img src={props.src} alt={props.alt} width={props.width} className={props.class} onClick={props.onClick}/>
    </>
  );
}

export default ImageTL;