import React from 'react';
import styles from './style.module.css';

const We = () => {
    return (<div className={styles.d}>

        <section>
            <h2>Nuestra Misión</h2>
            <p className={styles.p}>En Deltaquo, nuestra misión es proporcionar a nuestros usuarios acceso a las mejores ofertas y descuentos disponibles en línea. Nos enorgullece ofrecer una plataforma donde las ofertas son generadas tanto por bots de rastreo como por personas reales, lo que garantiza una amplia gama de opciones para nuestros usuarios.</p>
        </section>

        <section>
            <h2>Nuestro Equipo</h2>
            <p className={styles.p}>Nuestro equipo está formado por expertos en comercio electrónico y tecnología que trabajan incansablemente para brindar a nuestros usuarios la información más actualizada sobre ofertas y descuentos. Nos esforzamos por garantizar que cada oferta en nuestro sitio sea válida y beneficiosa para nuestros usuarios.</p>
        </section>
        <section>
            <p className={styles.p}>Bots que junto a colaboradores humanos comparten valiosa informaci&oacute;n</p>
        </section>

        <br /> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="64" height="64">
            <circle cx="32" cy="32" r="30" fill="#0088FF" />
            <path fill="#FFF" d="M38 45h-2c-6.627 0-12-5.373-12-12V29c0-6.627 5.373-12 12-12h2c6.627 0 12 5.373 12 12v4c0 6.627-5.373 12-12 12z" />
            <path fill="#0088FF" d="M32 15c6.075 0 11 4.925 11 11v4c0 6.075-4.925 11-11 11h-2c-6.075 0-11-4.925-11-11v-4c0-6.075 4.925-11 11-11h2z" />
            <circle cx="32" cy="23" r="6" fill="#FFF" />
        </svg>
    </div>);
}

export default We;